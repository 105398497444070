@import './../../styles/mixins';

//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.order-button {

    position: absolute;
    top:100%;
    //left: 0;
    right: 1rem;

    //margin-right: 1rem;

    @include flex(cc);
    z-index: 15;

    &__button {
        @include flex(cc);
        font-family: $font-buttons;
        font-size: 11px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 8px 25px;
        border-radius: 0 0 2px 2px;
        //letter-spacing: .3px;

        //opacity: .95;

        span {
            line-height: 16px;
            //margin-right: 10px;
        }

        svg {
            font-size: 14px;
            margin-left: 11px;
            //font-size: 16px;
            //display: none;
            //margin-top:-0.1px;
        }
    }
}