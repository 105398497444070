@import "./../../styles/mixins";
@import "./../../styles/theme";

.button-scroll-top {
  width: 60px;
  height: 60px;
  position: fixed;

  transition: background-color .1s;
  border-radius: 50%;
  background-color: $color-accent-hover;
  border:none;

  bottom: 20px;
  right: 20px;

  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.36);

  opacity: .87;

  @include md {
    display: none !important;
  }

  &:hover {
    background-color: $color-accent-active;
  }
  z-index: 11;

  &--fixed {
    position: absolute !important; // something's wrong i can feel it...
    bottom: 21px;

    //@include xxl {
    //  bottom: -5px;
    //}
    //
    //@include xl {
    //  bottom: -5px;
    //}
  }

  @include flex(cc);
  svg {
    width: 22px;
    height: 22px;
    opacity: .87;
  }
}