@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import "./theme";

form {

  .label {

    &-required {
      &:after {
        content: '*';
        display: inline;
        padding-left: 5px;
        color: $color-accent-active;
      }
    }

    &-checkbox {
      // we are using pretty-checkbox, but as always, nothing's gonna work without tweaks
      display: block;
      position: relative;

      label {

        vertical-align: middle;
        padding-left: 3px;
        //&:after, &:before {
        //  top: 50% !important;
        //  transform: translateY(-50%) !important;
        //}
        //
        //&:after {
        //  transform: translateY(-50%) scale(0.4) !important;
        //}
      }
    }
  }

  button:disabled {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}

.form {
  &-error {
    border-color: $color-accent-active !important;

    input, select, textarea {
      transition: none !important;
      border-color: $color-accent-active !important;
    }
    input, select {
      //border-left-color: transparent !important;
    }
    .fa-icon-wrapper {
      //transition: border-color .15s ease-in-out;
      border-color: $color-accent-active !important;
    }
    //background-color: lighten($color-danger-light, 15%) !important;

    input, select {
      border-left-color: transparent !important;
    }
  }

  &-check-input {
    cursor: pointer;
    //width: 1.15rem;
    //height: 1.15rem
  }

  &-check-label {
    padding-left: .75rem;
  }
}