@import './../../styles/theme';
@import './../../styles/mixins';

.footer {

  &-message {
    border-top: 1px solid rgba($color-default, .222);
    //border-bottom: 1px solid rgba($color-default, .222);
    text-align: center;
    background-color: $background-color-front;
    //margin-top: 1rem;

    font-size: 13px;
    text-transform: uppercase;

    margin-top: auto;

    a {
      @extend %generic-link;
      margin-left:7px;
      //vertical-align: middle;


      &:before {
        display: inline-block;
        font-size: 14px;
        margin-right: 5px;
        margin-top: -1px;
        //margin-bottom: -1px;
      }

      font-weight: 500;
    }

    @include md {
      & > * {
        display: block;
        width: 100%;
        margin:10px 0 !important;
        padding: 0 1rem;
        text-align: left;
      }
    }
  }

  .header-menu {
    @include flex(cs);

    a {
      font-weight: 400;
    }

    padding-bottom: 1.5rem; // pb-4
    border-bottom: 1px solid $color-default;
  }

  &__bottom {

    padding-top: 2rem;

    &__right {

      @include flex(rt);

      .site-info {
        margin-top: 1.3rem;
      }

      @include md {
        flex-direction: column;
        //align-items: flex-end;
      }

      & > div {
        @include flex(lb);
        flex-direction: column;

        &:last-of-type {
          margin-left: 4rem;
        }

        @include md {
          //width: 100%;
          margin-left: 0 !important;
          @include flex(rt);

          &:first-of-type {
            margin-bottom: 2rem;
          }
          .site-info__inline {
            text-align: left;
          }

          width: 100%;
          //max-width: 225px;
        }

        @include sm {
          margin: 2rem 0 !important;
        }
      }
    }
  }

  &__copyright {

    margin-top: 10px;
    font-size: 14px;
    color: $color-default;
    text-align: right;

    @include md {
      text-align: left;
    }
    @include sm {
      //text-align: left;
    }
  }
}