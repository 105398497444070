@import './../../styles/theme';
@import './../../styles/mixins';

.site-info {

  font-size: 14px;
  color: $text-color-dark;
  text-align: right;

  &__blocks {
    //margin-top: 1.5rem;
  }

  &__block {
    @include flex(lc);
    margin-bottom: 3px;

    color: $text-color-default;
    &__icon {
      font-size: 18px;
      margin-right: 10px;
    }

    &__text {
      font-size: 16px;
      //font-weight: 500;
    }
  }

  &__inline {

    &__icon {
      width: 24px;
      margin-left: 10px;
      //opacity: .8;
    }
  }
}