@import "./../../styles/theme";

.layout {

  &-default {

    body, main {
      height: 100%;
    }

    footer {
      margin-top: auto;
    }

    main > div, .container-fluid {
      margin-left:auto;
      margin-right: auto;
    }

    main > div {
      max-width: $content-max-width;
    }

    .container-fluid {
      max-width: 1920px;
    }

    .row {
      & > * {
        padding-left: calc(#{$padding-tiny} * 2);
        padding-right: calc(#{$padding-tiny} * 2);
      }
    }
  }
}