@import "theme";
@import "forms";

// import fonts
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

// import other global scss-files
@import "mixins";

html {
  @extend %scroll;
}

// styles go next
html, html body {
  font-size: 16px;
  font-family: $font-main;
  color: $text-color-default;
  margin:0;
  padding:0;

  min-height: 100vh;
  background-color: $background-color-back;

  // set the minimum with, if below - x-scroll-bar appears
  // it is just not worth optimizing for such small width :)
  min-width: 260px;

  main  {
    background-color: $background-color-front;
    margin-top: $header-height;
  }

  .main__inner {
    @extend %full-height-header;
  }

  a {
    color: $text-color-default;
    text-decoration: none;

    transition: color .1s ease-in-out;

    &:hover {
      color: $color-accent-active;
    }
  }

  //overflow-anchor: none !important;
  //scroll-snap-stop: normal !important;
  //overscroll-behavior: unset !important;
  //scroll-behavior: unset !important;
}

html body {
  will-change: position;
}

html body.modal-open {
  position: fixed;
  overflow: hidden !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;

  //@include xs {
  //  #root {
  //    display: none !important;
  //  }
  //}
}

.link-disabled {
    user-select: none;
    pointer-events: none !important;
    opacity: .5;
}

.button {

  border:none !important;
  padding: 5px 13px;
  font-weight: 500;
  transition: background-color .1s;
  white-space: nowrap;

  &-accent {

    background-color: rgba($color-accent-hover, .7) !important;
    color: $text-color-default;

    &:hover {
      //background-color: $color-accent-active;
      background-color: rgba($color-accent-active, .7) !important;
    }
  }
}


#__react-alert__ {

  & > div {

    z-index: 1111 !important;

    & > div > div {
      width: auto !important;
      max-width: 666px; // ave satan!
    }
  }

  & > div > div > div {
    //min-height: 46px;
    border-radius: 30px !important;

    span {
      text-align: center;
      font-family: 'Roboto', sans-serif !important;
      //text-transform: initial;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}