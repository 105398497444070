@import "./../../styles/mixins";
@import "./../../styles/theme";

.header-menu {

  &__inner {
    @extend %scroll;
    overflow-y: hidden;

    &__header {
      @include flex(cc);
      width: 100%;

      display: none;

      .header-info {
        margin-left:0;
      }
    }

    &__footer {
      display: none;
      margin-top: 2rem;
      padding-top: 1.5rem;

      border-top: 1px solid rgba($color-default, .444);

    }
  }

  &:not(.header-menu--mobile) {

    @include md {
      //display: none;

      .header-menu {
        &__inner, &__list, &__item {
          width: 100%;
        }
        &__list {
          flex-direction: column;
        }
        &__item {
          text-align: left;
          padding: .5rem 0;
        }
      }
    }
  }

  &--mobile {
    button {
      display: none;

      width: 44px;
      height: 44px;
      border:none;
      background-color: transparent;
      color: $text-color-default;
      margin-left:auto;

      @include md {
        display: block;
      }

      @include xxs {
        display: none !important;
      }

      position: absolute;
      right: 1rem;
    }

    @include md {

      .header-menu__inner {
        position: fixed;
        top:0;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        right: -100vw;
        //left: 100vw;
        z-index: 22;
        background-color: $background-color-back;
        visibility: hidden;

        padding: 1rem;

        transition: transform .3s ease-in-out;

        &--opened {
          //right: 0;
          transform: translateX(-100vw);
          left: unset;
        }

        &--visible {
          visibility: visible;
        }

        .header-menu__list {
          flex-direction: column;
          justify-content: center;
          //height: 100%;

          & > div {
            padding: 1rem;
          }
        }

        &__header {
          display: flex;
        }

        &__footer {
          display: flex;
          justify-content: center;
        }

        button {
          position: absolute;
          top: 10px;
          right: 1rem;
          font-size: 18px;
        }
      }
    }
  }

  &__list {
    @include flex(rc);
    width: 100%;
  }

  &__item {

    &:last-of-type {
      padding-right: 0;
    }

    padding: 10px 20px;

    &__link {
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      color: $text-color-default;
      transition: color .05s;

      &:hover {
        color: $color-accent;
      }
    }
  }
}