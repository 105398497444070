@import "./../../styles/mixins";
@import "./../../styles/theme";

.header {
  box-shadow: 0 4px 7px -2px rgba($background-color-back,.555);
  z-index: 12;
  position: fixed;
  left:0;
  width: 100%;

  height: $header-height;

  background-color: rgba($background-color-back, .95);

  transition: transform .2s ease-in-out, opacity .2s ease-in-out, box-shadow .2s ease-in-out;
  top: 0 !important;

  @include sm {
    .logotype {
      width: 160px;
    }
  }

  &--hidden {
    transform: translateY(-$header-height);
    box-shadow: none;
  }

  &__right {
    padding: 10px 0;
  }
  &__left {
    padding: 17px 0;
    height: $header-height;
    @include flex(lc);

    .header-info {
      @include sm {
        display: none;
      }
    }
  }

  &__left, &__right {
    width: 100%;

    @include md {
      padding: 10px 0;
    }
  }

  &-info {

    margin-left:25px;
    position: relative;

    &__top {
      @include flex(lc);
    }

    &__icon {
      font-size: 20px;
      margin-right: 10px;
    }

    &__link {
      font-size: 18px;
    }

    &__bottom {
      position: absolute;
      top:80%;

      white-space: nowrap;
      font-size: 11px;
      opacity: .5;

      width: 100%;
      text-align: right;
    }
  }
}