@import "./../../styles/mixins";

.logotype {

  user-select: none;

  display: block;
  width: 200px;
  height: 25px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}