@import "./../../styles/theme";

.language {

  &-selector {

    flex-wrap: wrap;

    &__header {
      font-size: 14px;
    }

    &__select {

      min-width: 100px;
      color: $text-color-default !important;


      border:2px solid $color-default;
      border-radius: 3px;
      font-size: 14px;
      padding:3px 5px;
      margin-left:10px;
      background-color: $background-color-accent;

      &__option {
        color: $text-color-default !important;
      }
    }
  }
}