@import "./../../styles/theme";
@import "./../../styles/mixins";

.error-boundary {
  background-color: $background-color-back;
  //margin-bottom: -1.5rem;

  //@include flex(cc);

  //min-height: $full-height;

  &__text {
    //font-size: $text-size-large;
    //padding-bottom: $padding-default;
    max-width: 650px;
    margin-left:auto;
    margin-right: auto;
  }

  a {
    color: $color-accent-hover;
  }

  min-height: $full-height;
}